import React from 'react';

export const FormInput = ({
  register,
  name,
  type,
  regObj,
  disabled,
  placeholder,
  hasAutoFocus,
  autoComplete,
  required,
}) => (
  <input
    id={name}
    type={type}
    className="form-control"
    name={name}
    ref={register(regObj)}
    placeholder={placeholder}
    disabled={disabled}
    autoFocus={hasAutoFocus}
    autoComplete={autoComplete}
    required={required}
  />
);
