class _RealmConfig {
  constructor() {
    this._config = window.appState.config.realmConfig || {};
  }

  get(name) {
    return this._config[name];
  }
}

export const realmConfig = new _RealmConfig();
