import React from 'react';
import {FacebookButton} from './FacebookButton';
import {LinkedInButton} from './LinkedInButton';
import {GoogleButton} from './GoogleButton';
import {Col, Row} from 'reactstrap';

export function SocialLogin({socialLoginLinks = {}}) {
  const {facebook, linkedin, google} = socialLoginLinks;

  return (
    <Row>
      <Col>
        <div id="social-login">
          <ul className="d-flex justify-content-center">
            {Boolean(google) && (
              <li xs={4} className="text-center">
                <GoogleButton target={google} />
              </li>
            )}
            {Boolean(linkedin) && (
              <li xs={4} className="text-center">
                <LinkedInButton target={linkedin} />
              </li>
            )}
            {Boolean(facebook) && (
              <li xs={4} className="text-center">
                <FacebookButton target={facebook} />
              </li>
            )}
          </ul>
        </div>
      </Col>
    </Row>
  );
}
