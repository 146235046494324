import React from 'react';
import {FormattedMessage} from 'react-intl';

export function MessagePage({titleKey, messageKey, extraMessage}) {
  return (
    <div className="text-center mt-2">
      {titleKey && <FormattedMessage id={titleKey} tagName="h1" />}
      {messageKey && <FormattedMessage id={messageKey} />}
      {extraMessage && <div style={{background: 'hotpink'}}>{extraMessage}</div>}
    </div>
  );
}
