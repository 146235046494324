import React from 'react';
import PropTypes from 'prop-types';
//import {webFeature} from '../../config/webFeature';
import {Col, Form, Row} from 'reactstrap';
import {useForm, FormContext} from 'react-hook-form';
import {LabeledInput} from '../../common/labeledinput/LabeledInput';
import {PASSWORD_PATTERN} from '../../constants/regexPatterns';
import {SocialLogin} from '../../common/social/SocialLogin';
import {SocialLoginDivider} from '../../common/social/SocialLoginDivider';
import {SupportLinks} from '../../common/SupportLinks';
import {FormattedMessage} from 'react-intl';
import {Button} from 'reactstrap';
import useFormattedMessage from '../../common/useFormattedMessage';

// Note: This is from a config value
// const rememberMeEnabled = webFeature.enabled('rememberlogin');

export function SetPasswordPage({submit, rememberMe, socialLoginLinks}) {
  const methods = useForm();
  const {formatMessage} = useFormattedMessage();
  const {facebook, linkedin, google} = socialLoginLinks;

  const hideLabel = window.appState.config.aleTheme === 'bx2023';

  const onSubmit = submitObj =>
    submit({
      password: submitObj.passwordVal,
    });

  return (
    <div className="xs-input">
      <FormContext {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="text-center">
            <Col sm={12} className="mt-2 mb-2">
              <FormattedMessage id="loginsite.setpw.intro.title" tagName="h3" />
            </Col>
            <Col sm={12} className="mb-2">
              <FormattedMessage id="loginsite.setpw.intro.text" tagName="p" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={'mb-3'}>
              <LabeledInput
                className="form-control"
                type="password"
                hideLabel={hideLabel}
                name="passwordVal"
                label={formatMessage('loginsite.setpw.label')}
                placeholder={formatMessage('loginsite.password.placeholder')}
                regObj={PASSWORD_PATTERN}
                hasAutoFocus
                autoComplete="new-password"
                required
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Button
                className="btn btn-block"
                color="primary"
                type="submit"
                value="Set password"
                disabled={methods.formState.isSubmitting}
              >
                <FormattedMessage id="loginsite.action.login" />
              </Button>
            </Col>
          </Row>
          {(facebook || linkedin || google) && (
            <>
              <SocialLoginDivider />
              <Row className="text-center">
                <Col sm={12} className="mb-2">
                  <FormattedMessage id="loginsite.setpw.social.title" tagName="h3" />
                </Col>
                <Col sm={12} className="mb-2">
                  <FormattedMessage id="loginsite.setpw.social.text" tagName="p" />
                </Col>
              </Row>
            </>
          )}
          <SocialLogin socialLoginLinks={socialLoginLinks} />
          <SupportLinks hasForgotPasswordLink={false} hasBackToStartLink={false} />
        </Form>
      </FormContext>
    </div>
  );
}

SetPasswordPage.propTypes = {
  email: PropTypes.string,
  submit: PropTypes.func.isRequired,
};
