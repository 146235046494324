import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import useFormattedMessage from './useFormattedMessage';

export function useMessageDisplay({status}) {
  const {formatMessage} = useFormattedMessage();

  useEffect(() => {
    let key = null;
    let message = null;

    if (status === 'ok') return;

    switch (status) {
      case 'locked':
        key = 'loginsite.message.lockedaccount';
        break;
      case 'failed':
        key = 'loginsite.login.invalidcredentials';
        break;
      case 'invalidPassword':
        key = 'loginsite.password.password.policy';
        break;
      case 'invalidCode':
        key = 'loginsite.code.invalid';
        break;
      case 'attemptsLimit':
        key = 'loginsite.attempts.limit';
        break;
      case 'ssouser':
        key = 'loginsite.password.forbidden';
        break;
      default:
        message = `Unknown status: ${status}`;
    }

    const msg = key ? formatMessage(key) : message;

    toast.error(<p className="font-weight-bold small mb-2">{msg}</p>);
  });
}

useMessageDisplay.propTypes = {
  status: PropTypes.string.isRequired,
};
