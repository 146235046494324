import React from 'react';
import {LoadingSpinner} from '../../common/LoadingSpinner';

export function RedirectPage({details}) {
  window.location.href = details.redirect;

  return (
    <div className="mt-5 text-center">
      <LoadingSpinner />
    </div>
  );
}
