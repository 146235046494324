import {useIntl} from 'react-intl';

const useFormattedMessage = () => {
  const intl = useIntl();

  const formatMessage = id => {
    return intl.formatMessage({id: id});
  };

  return {formatMessage};
};

export default useFormattedMessage;
