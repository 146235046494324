import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

export function SingleHybridPage({submit}) {
  return (
    <div className="text-center">
      <FormattedMessage id="loginsite.shybrid.loginmethod" tagName={'h2'} />
      <Row className="mt-5">
        <Col sm={6}>
          <Button className="btn btn-block mb-3" color="primary" onClick={() => submit({method: 'sso'})}>
            <FormattedMessage id="loginsite.shybrid.loginsso" />
          </Button>
        </Col>
        <Col sm={6}>
          <Button className="btn btn-block mb-3" color="primary" onClick={() => submit({method: 'password'})}>
            <FormattedMessage id="loginsite.shybrid.passlogin" />
          </Button>
        </Col>
      </Row>
    </div>
  );
}

SingleHybridPage.propTypes = {
  submit: PropTypes.func.isRequired,
};
