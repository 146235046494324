import React from 'react';
import {FormattedMessage} from 'react-intl';
import {SupportLinks} from '../../common/SupportLinks';

export function SentPage() {
  return (
    <>
      <div className="text-center">
        <FormattedMessage id="loginsite.message.mailsent.title" tagName="h2" />
        <FormattedMessage id="loginsite.message.mailsent" tagName="p" />
      </div>
      <SupportLinks hasForgotPasswordLink={false} hasBackToStartLink={true} />
    </>
  );
}
