import React, {useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {post} from '../../fetch/fetchOptions';
import {useErrorDisplay} from '../../common/useErrorDisplay';
import {LoadingSpinner} from '../../common/LoadingSpinner';
import {twofactorauthenticateUrl} from '../../fetch/urls';
import {TwoFactorAuthPage} from './TwoFactorAuthPage';

export function TwoFactorAuth({lsid, locale, details, updateDispatcher}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useErrorDisplay(error);

  const submit = async ({lsid, code, rememberMe}) => {
    setLoading(true);
    setError(null);

    async function doPost() {
      try {
        const res = await axios(
          post({
            url: twofactorauthenticateUrl(),
            data: {lsid, code, rememberMe},
          }),
        );
        setLoading(false);
        updateDispatcher(res.data);
      } catch (exception) {
        console.log('Exception when submitting data: ', exception);
        setLoading(false);
        setError(exception);
      }
    }

    await doPost();
  };

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return <TwoFactorAuthPage submit={submit} lsid={lsid} locale={locale} details={details} />;
  }
}

TwoFactorAuth.propTypes = {
  updateDispatcher: PropTypes.func.isRequired,
  lsid: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
};
