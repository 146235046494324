import React from 'react';
import PropTypes from 'prop-types';

import {ERROR_REPORT} from './endpoints';

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here

    if (XMLHttpRequest) {
      const xhr = new XMLHttpRequest();
      const url = ERROR_REPORT();
      xhr.open('POST', url, true);
      xhr.withCredentials = true;

      // Send the proper header information along with the request
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

      xhr.onreadystatechange = () => {
        // Call a function when the state changes.
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          // Request finished. Do processing here.
        }
      };

      const formData = `errorMessage=${encodeURIComponent(
        error ? error.toString() : '',
      )}&callStack=${encodeURIComponent(errorInfo.componentStack)}&url=${encodeURIComponent(window.location.href)}`;

      if (window.Raven) {
        window.Raven.captureException(error);
      }

      xhr.send(formData);
    }
  }

  /*eslint no-restricted-globals: 0*/
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="message">
          <h1>Something went wrong</h1>
          <p>
            <strong>{this.state.error && this.state.error.toString()}</strong>
          </p>
          <p>
            <button type="button" onClick={() => history.go(-1)}>
              <div>Go back</div>
            </button>
          </p>
          <p>
            <strong>Time:</strong>
            {new Date().toString()}
          </p>
          <div>
            <strong>Technical message:</strong>
            <pre>{this.state.errorInfo.componentStack}</pre>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
