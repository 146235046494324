import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {post} from '../../fetch/fetchOptions';
import {ResetPasswordPage} from './ResetPasswordPage';
import axios from 'axios';
import {useErrorDisplay} from '../../common/useErrorDisplay';
import {LoadingSpinner} from '../../common/LoadingSpinner';
import {resetPasswordUrl} from '../../fetch/urls';

export function ResetPassword({locale, details, updateDispatcher}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useErrorDisplay(error);

  const submit = async ({password}) => {
    setLoading(true);
    setError(null);

    async function doPost() {
      try {
        const res = await axios(
          post({
            url: resetPasswordUrl(),
            data: {
              resetId: details.resetId,
              password,
            },
          }),
        );
        setLoading(false);
        updateDispatcher(res.data);
      } catch (exception) {
        console.log('Exception when submitting data: ', exception);
        setLoading(false);
        setError(exception);
      }
    }

    await doPost();
  };

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return <ResetPasswordPage locale={locale} submit={submit} />;
  }
}

ResetPassword.propTypes = {
  updateDispatcher: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
};
