import React from 'react';
import {config} from '../config/config';

export function BackgroundContainer(props) {
  const backgroundUrl = config.get('backgroundUrl');

  const style = backgroundUrl ? {backgroundImage: `url(${backgroundUrl})`} : {};
  return (
    <>
      <div className="bg-container" style={style}>
        <div className="box-bg-color h-100"></div>
        {props.children}
      </div>
    </>
  );
}
