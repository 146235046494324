import React from 'react';
import {FormattedMessage} from 'react-intl';
import {config} from '../../config/config';
const cdn = config.get('baseUrls').cdn;

export function LinkedInButton({target}) {
  return (
    <a href={target} id="linkedinlogin">
      <img src={`${cdn}cocobox/img/login2/linkedin-circle.png`} alt="Logga in med LinkedIn	" draggable="false" />
      <br />
      <FormattedMessage id="loginsite.linkedin" tagName="span" />
    </a>
  );
}
