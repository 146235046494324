import React from 'react';
import {Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {FORGOT_PASSWORD_ROUTE} from '../constants/routes';
import {FormattedMessage, useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {config} from '../config/config';

/**
 * Gets the link for the support text. This function prefers the url to email and fall backs to an empty
 * string if neither exists.
 *
 * @returns {string}
 */
function getSupportUrl() {
  const supportUrl = config.get('realmConfig')['upweb.support.url'];
  const supportMail = config.get('realmConfig')['upweb.support.email'];

  if (supportUrl) {
    return supportUrl;
  } else if (supportMail) {
    return 'mailto:' + supportMail;
  } else {
    return '';
  }
}

export function SupportLinks({hasForgotPasswordLink = true, hasBackToStartLink = false}) {
  const intl = useIntl();

  let supportUrl = getSupportUrl();

  const altSupport = !!intl.messages['loginsite.supportlinks.support.alt'];

  const supportText = altSupport
    ? intl.messages['loginsite.supportlinks.support.alt']
    : intl.messages['loginsite.supportlinks.support'];

  // Hardcoded workaround. This is only here temporary until we can remove the whole altSupport thing.
  // Should not live longer than a month or two after 2023-05-16.
  if (altSupport) {
    supportUrl = 'https://support.kenblanchard.com/hc/en-us/articles/15314692121115';
  }

  const supportExtraStyles = altSupport ? {fontSize: '170%'} : {};

  return (
    <Row>
      <Col className="text-center p-2 login-support-links">
        {hasForgotPasswordLink && (
          <Link to={FORGOT_PASSWORD_ROUTE}>
            <FormattedMessage id="loginsite.action.forgotpw" />
          </Link>
        )}
        {hasBackToStartLink && (
          <Link to={'/'}>
            <FormattedMessage id="loginsite.action.back" />
          </Link>
        )}
        {(hasBackToStartLink || hasForgotPasswordLink) && <br />}
        <a href={supportUrl} target="_blank" rel="noopener noreferrer" style={supportExtraStyles}>
          {supportText}
        </a>
      </Col>
    </Row>
  );
}

SupportLinks.propTypes = {
  hasForgotPasswordLink: PropTypes.bool.isRequired,
  hasBackToStartLink: PropTypes.bool.isRequired,
};
