import React from 'react';
import {Col, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

export const SocialLoginDivider = () => (
  <Row className="my-3">
    <Col className="text-center">
      <hr className="float-left" />
      <span className="or-text">
        <FormattedMessage tagName="span" id="loginsite.social.or" />
      </span>
      <hr className="float-right" />
    </Col>
  </Row>
);
