export const DEFAULT_PATTERN = {
  required: 'Enter a valid value',
  pattern: {
    value: /.+/i,
    message: 'Enter a valid value',
  },
};

export const PHONE_PATTERN = {
  required: 'Phone number must be a plus sign followed by 2-15 digits',
  pattern: {
    value: /^\+\d{2,15}$/,
    message: 'Phone number must be a plus sign followed by 2-15 digits',
  },
};

export const EMAIL_PATTERN = {
  required: 'Enter a valid email',
  pattern: {
    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
    message: 'Enter a valid email',
  },
};

export const USERNAME_PATTERN = {
  required: 'Enter a valid user name',
  pattern: {
    value: /^\S+$/, //eslint-disable-line
    message: 'Enter a valid user name',
  },
};

export const PASSWORD_PATTERN = {
  required: 'Enter a valid password (min 4 chars)',
  pattern: {
    value: /^.{4,}$/,
    message: 'Enter a valid password (min 4 chars)',
  },
};
