import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {realmConfig} from '../config/realmConfig';
import {config} from '../config/config';

const privacyPolicyUrl = realmConfig.get('cocosite.privacypolicy.url');
const tosUrl = realmConfig.get('cocosite.tos.url');

export const FooterBX2023 = () => {
  const copyright = config.get('copyright');

  return (
    <footer className="px-4 bg-primary">
      <Container fluid>
        <Row>
          <Col md={6} className="d-flex">
            <div className="d-flex flex-column flex-lg-row align-items-center">
              <span>
                <p className="mb-0" dangerouslySetInnerHTML={{__html: copyright}}></p>
              </span>
            </div>
          </Col>
          <Col md={6} className="d-flex flex-row align-items-center  justify-content-end">
            <div className="d-flex flex-row align-items-center">
              <a href={tosUrl} target="_blank" rel="noopener noreferrer">
                <p className="small mb-0">
                  <FormattedMessage id="loginsite.footer.terms" />
                </p>
              </a>
              <span className="ml-2 mr-2">|</span>
              <a className="text-white" href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                <p className="small mb-0">
                  <FormattedMessage id="loginsite.footer.privacy" />
                </p>
              </a>
              <span className="ml-2 mr-2">|</span>
              <a
                className="text-white"
                href="https://www.learnifier.com?utm_campaign=learnifier&amp;utm_medium=footerlogo&amp;utm_source=loginsite"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-center small mb-0">
                  <FormattedMessage id="loginsite.footer.powered.whitelabel" />
                </p>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
