import React from 'react';
import {Col, Form, Row} from 'reactstrap';
import {useForm, FormContext} from 'react-hook-form';
import {LabeledInput} from '../../common/labeledinput/LabeledInput';
import {FormattedMessage} from 'react-intl';
import {Button} from 'reactstrap';
import useFormattedMessage from '../../common/useFormattedMessage';
import {config} from '../../config/config';
import {webFeature} from '../../config/webFeature';

const rememberMeEnabled = webFeature.enabled('rememberlogin');

export function TwoFactorAuthPage({lsid, submit, locale, details, code}) {
  const methods = useForm();
  const {formatMessage} = useFormattedMessage();

  const onSubmit = ({code, rememberMe}) =>
    submit({
      lsid,
      code,
      ...(rememberMeEnabled && {rememberMe}),
    });

  return (
    <div className="xs-input">
      <FormContext {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="text-center"></Row>
          <Row>
            <Col xs={12} className={'mb-3 text-center'}>
              <div>
                {details.extra && (
                  <>
                    <h2>Your code:</h2>
                    <h4 className={'text-white'}>{details.extra}</h4>
                  </>
                )}
                <FormattedMessage id="loginsite.twofactor.info" />
              </div>
              <LabeledInput
                type="text"
                name="code"
                mandatory
                valid={true}
                placeholder={'Your code here'}
                hasAutoFocus
                required
              />
            </Col>
            {rememberMeEnabled && (
              <Col xs={12}>
                <div className="d-flex justify-content-center mt-1">
                  <LabeledInput
                    className="form-control"
                    type="checkbox"
                    name="rememberMe"
                    label={formatMessage('loginsite.remember.label')}
                    defaultValue={false}
                  />
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12}>
              <Button className="btn btn-block" color="primary" type="submit" disabled={methods.formState.isSubmitting}>
                <FormattedMessage id="loginsite.action.login" />
              </Button>
            </Col>
            <Col xs={12} className={'text-center p-2 login-support-links'}>
              <a href={config.get('realmConfig')['upweb.support.url']} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="loginsite.supportlinks.support" />
              </a>
            </Col>
            {/*<Col xs={12} className={'text-center'}>
              <h5 className={'p-0 mt-2 text-white '}>Didn't get your code?</h5>
              <a href={'#'} className={'border-bottom'}>
                Send new code
              </a>
            </Col>*/}
          </Row>
        </Form>
      </FormContext>
    </div>
  );
}
