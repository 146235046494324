import React from 'react';
import {useFormContext} from 'react-hook-form';
import {FormLabel} from './FormLabel';
import {FormInput} from './FormInput';
import {FormInputCheckbox} from './FormInputCheckbox';
import classNames from 'classnames';
import {FormCustomFieldDropdown} from './FormCustomFieldDropdown';

const FIELD_FORMAT_COMPONENTS = {
  dropdown: FormCustomFieldDropdown,
  default: FormInput,
};

export const LabeledInput = ({
  type,
  format,
  name,
  label,
  placeholder,
  regObj = {},
  disabled = false,
  hasAutoFocus = false,
  autoComplete,
  required,
  hideLabel = false,
  ...rest
}) => {
  const {register, errors} = useFormContext(); // retrieve all hook methods

  const nameArray = name.split('.');
  let errMessage = undefined;

  if (errors[nameArray[0]]) {
    if (nameArray.length === 1) {
      // i.e. name of input field = password
      let errObj = errors[nameArray[0]];
      if (errObj) {
        errMessage = errObj.message;
      }
    } else {
      // i.e. name of input field = data.firstName
      let errObj = errors[nameArray[0]][nameArray[1]];
      if (errObj) {
        errMessage = errObj.message;
      }
    }
  }

  const Component = FIELD_FORMAT_COMPONENTS[format] || FIELD_FORMAT_COMPONENTS.default;

  return (
    <div className={classNames('label-input form-group', {'mb-3': type !== 'hidden', 'mb-0': type === 'hidden'})}>
      {type !== 'checkbox' ? (
        <>
          {type !== 'hidden' && (
            <FormLabel
              className={classNames('', {'sr-only': hideLabel})}
              name={name}
              label={label}
              regObj={regObj}
              required={required}
            />
          )}
          <Component
            {...rest}
            register={register}
            name={name}
            regObj={regObj}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            hasAutoFocus={hasAutoFocus}
            autoComplete={autoComplete}
            required={required}
          />
        </>
      ) : (
        <>
          <FormInputCheckbox register={register} name={name} type={type} regObj={regObj} disabled={disabled} />
          <FormLabel name={name} label={label} regObj={regObj} required={required} />
        </>
      )}

      {errMessage && <div className="d-block invalid-feedback">{errMessage}</div>}
    </div>
  );
};
