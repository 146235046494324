import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Row, Col} from 'reactstrap';
import {LabeledInput} from '../../common/labeledinput/LabeledInput';
import {EMAIL_PATTERN, USERNAME_PATTERN} from '../../constants/regexPatterns';
import {useForm, FormContext} from 'react-hook-form';
import {LoadingSpinner} from '../../common/LoadingSpinner';
import {FormattedMessage} from 'react-intl';
import {webFeature} from '../../config/webFeature';
import {SupportLinks} from '../../common/SupportLinks';
import useFormattedMessage from '../../common/useFormattedMessage';
import {usernameEnabled} from '../../config/username';

const rememberMeEnabled = webFeature.enabled('rememberlogin');
const socialLoginEnabled = webFeature.enabled('sociallogin');

export function HybridPage({passwordEnabled, setPasswordDisabled, loading, details, submit}) {
  const {email} = details;
  const methods = useForm();

  const {formatMessage} = useFormattedMessage();

  useEffect(() => {
    // hides password field if email field is changed

    if (passwordEnabled) {
      setPasswordDisabled();
    }
  }, [methods.watch('email')]);

  useEffect(() => {
    // post error => sets email field with previously posted email value
    if (email) {
      methods.setValue('email', email);
    }
  }, [email]);

  let text;
  if (passwordEnabled) {
    text = <FormattedMessage id="loginsite.action.login" />;
  } else {
    text = <FormattedMessage id="loginsite.hybrid.next" />;
  }

  const onSubmit = submitObj =>
    submit({
      email: submitObj.email,
      password: submitObj.password,
      ...(rememberMeEnabled && {rememberMe: submitObj.rememberMe}),
    });

  let inputType, inputPattern;
  if (usernameEnabled()) {
    inputType = 'text';
    inputPattern = USERNAME_PATTERN;
  } else {
    inputType = 'email';
    inputPattern = EMAIL_PATTERN;
  }

  const hideLabel = window.appState.config.aleTheme === 'bx2023';

  return (
    <FormContext {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {socialLoginEnabled && (
          <Row>
            <Col className="d-flex justify-content-center">
              <FormattedMessage id="loginsite.social.signin.title" tagName="h2" />
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <LabeledInput
              type={inputType}
              hideLabel={hideLabel}
              name="email"
              mandatory
              valid={true}
              label={formatMessage(usernameEnabled() ? 'loginsite.username.label' : 'loginsite.email.label')}
              placeholder={formatMessage(
                usernameEnabled() ? 'loginsite.username.placeholder' : 'loginsite.email.placeholder',
              )}
              regObj={inputPattern}
              hasAutoFocus
              autoComplete="username"
              required
            />
            {passwordEnabled && (
              <>
                <LabeledInput
                  type="password"
                  hideLabel={hideLabel}
                  label={formatMessage('loginsite.password.label')}
                  placeholder={formatMessage('loginsite.password.placeholder')}
                  name="password"
                  regObj={{required: true}}
                  hasAutoFocus
                  autoComplete="current-password"
                />
              </>
            )}
          </Col>
          {rememberMeEnabled && (
            <Col xs={12}>
              <div className="d-flex justify-content-center mt-1">
                <LabeledInput
                  className="form-control"
                  type="checkbox"
                  name="rememberMe"
                  label={formatMessage('loginsite.remember.label')}
                  defaultValue={false}
                />
              </div>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={12}>
            {loading && (
              <div className="pb-5">
                <LoadingSpinner />
              </div>
            )}
            <Button
              className="btn btn-primary btn-block mt-1"
              color="primary"
              type="submit"
              disabled={methods.formState.isSubmitting}
            >
              {text}
            </Button>
          </Col>
        </Row>

        <SupportLinks hasForgotPasswordLink={passwordEnabled} hasBackToStartLink={false} />
      </Form>
    </FormContext>
  );
}

HybridPage.propTypes = {
  details: PropTypes.object.isRequired,
  passwordEnabled: PropTypes.bool.isRequired,
  setPasswordDisabled: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};
