import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import {customFieldChoicesUrl} from '../../fetch/urls';
import axios from 'axios';
import {get} from '../../fetch/fetchOptions';
import {LoadingSpinner} from '../LoadingSpinner';
import {useErrorDisplay} from '../useErrorDisplay';
import {FormattedMessage} from 'react-intl';

const renderContent = (data, value, placeholder) => {
  if (data.loading) {
    return <LoadingSpinner center />;
  } else if (data.error) {
    return (
      <span className="text-red">
        <FormattedMessage id="error.server_error" />
      </span>
    );
  }
  return <span>{data.choices[value] || value || placeholder}</span>;
};

export const FormCustomFieldDropdown = ({
  register,
  regObj,
  lsid,
  locale,
  fieldId,
  name,
  disabled,
  placeholder,
  required,
  withErrorDisplay = true,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({loading: true, choices: {}, error: null});

  const {setValue, watch} = useFormContext();
  const value = String(watch(name));

  const fetch = () => {
    setOpen(false);
    setData({loading: true, choices: {}, error: null});
    axios(get({url: customFieldChoicesUrl(lsid, fieldId, locale)}))
      .then(response => response.data)
      .then(choices => setData({choices, loading: false, error: null}))
      .catch(error => setData({error, loading: false, choices: {}}));
  };

  useEffect(fetch, []);

  useErrorDisplay(withErrorDisplay && data.error);

  return (
    <>
      <input type="hidden" ref={register(regObj)} name={name} required={required} />

      <Dropdown
        disabled={disabled || data.loading}
        isOpen={open}
        toggle={() => (data.error ? fetch() : !data.loading && setOpen(current => !current))}
      >
        <DropdownToggle
          caret={!data.error && !data.loading}
          className={classNames(
            'form-control d-flex align-items-center w-100',
            data.loading ? 'justify-content-center' : 'justify-content-between',
          )}
        >
          {renderContent(data, value, placeholder)}
        </DropdownToggle>
        <DropdownMenu className="w-100">
          {Object.keys(data.choices).map(choice => (
            <DropdownItem
              key={choice}
              className={classNames('text-truncate', {active: choice === value})}
              onClick={() => setValue(name, choice)}
            >
              {data.choices[choice]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

FormCustomFieldDropdown.propTypes = {
  register: PropTypes.func.isRequired,
  regObj: PropTypes.object.isRequired,
  lsid: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  withErrorDisplay: PropTypes.bool,
};
