import React, {lazy, useState} from 'react';
import {Password} from './pages/password/Password';
import {RedirectPage} from './pages/redirect/RedirectPage';
import {MainLayout} from './layouts/MainLayout';
import {config} from './config/config';
import {Hybrid} from './pages/hybrid/Hybrid';
import {SingleHybrid} from './pages/singlehybrid/SingleHybrid';
import {SetPassword} from './pages/setPassword/SetPassword';
import {ResetPassword} from './pages/resetPassword/ResetPassword';
import {MessagePage} from './pages/message/MessagePage';
import {TwoFactorAuth} from './pages/twoFactor/TwoFactorAuth';

const ConfirmUserDetails = lazy(() =>
  import(/* webpackChunkName: "ConfirmUserDetails" */ './pages/details/ConfirmUserDetails'),
);
const ConfirmTos = lazy(() => import(/* webpackChunkName: "ConfirmTos" */ './pages/tos/ConfirmTos'));

export function Dispatcher() {
  const [loginState, setLoginState] = useState(config.get('loginState'));

  const updateDispatcher = newLoginState => {
    setLoginState(newLoginState);
  };

  const dispatcherMap = {
    password: {
      component: () => <Password updateDispatcher={updateDispatcher} details={loginState.details} />,
      Layout: MainLayout,
    },
    twoFactorAuth: {
      component: () => (
        <TwoFactorAuth
          updateDispatcher={updateDispatcher}
          locale={loginState.locale}
          lsid={loginState.lsid}
          details={loginState.details}
        />
      ),
      Layout: MainLayout,
    },
    setPassword: {
      component: () => (
        <SetPassword
          updateDispatcher={updateDispatcher}
          lsid={loginState.lsid}
          locale={loginState.locale}
          details={loginState.details}
        />
      ),
      Layout: MainLayout,
    },
    resetPassword: {
      component: () => (
        <ResetPassword
          updateDispatcher={updateDispatcher}
          lsid={loginState.lsid}
          locale={loginState.locale}
          details={loginState.details}
        />
      ),
      Layout: MainLayout,
    },
    hybrid: {
      component: () => <Hybrid updateDispatcher={updateDispatcher} details={loginState.details} />,
      Layout: MainLayout,
    },
    singlehybrid: {
      component: () => (
        <SingleHybrid
          updateDispatcher={updateDispatcher}
          details={loginState.details}
          ssoState={() => ({
            status: 'ok',
            action: 'redirect',
            details: loginState.details,
          })}
          passwordState={() => ({
            status: 'ok',
            action: 'password',
            details: loginState.details,
          })}
        />
      ),
      Layout: MainLayout,
      colSize: {md: {size: 8, offset: 2}, lg: {size: 6, offset: 3}},
    },
    confirmdetails: {
      component: () => (
        <ConfirmUserDetails
          updateDispatcher={updateDispatcher}
          lsid={loginState.lsid}
          locale={loginState.locale}
          details={loginState.details}
        />
      ),
      Layout: MainLayout,
    },
    tos: {
      component: () => (
        <ConfirmTos
          updateDispatcher={updateDispatcher}
          lsid={loginState.lsid}
          locale={loginState.locale}
          details={loginState.details}
        />
      ),
      Layout: MainLayout,
    },
    message: {
      component: () => (
        <MessagePage
          titleKey={loginState.details.titleKey}
          messageKey={loginState.details.bodyKey}
          extraMessage={loginState.details.extraMessage}
        />
      ),
      Layout: MainLayout,
    },
    redirect: {component: () => <RedirectPage details={loginState.details} />, Layout: MainLayout},
  };

  if (dispatcherMap[loginState.action]) {
    const {Layout, component, colSize} = dispatcherMap[loginState.action];
    return (
      <Layout status={loginState.status} colSize={colSize}>
        {component()}
      </Layout>
    );
  } else {
    console.log('Unknown state: ', loginState);
    return (
      <MainLayout status={loginState.status}>
        <div>State '{loginState.action}' is not implemented.</div>
      </MainLayout>
    );
  }
}
