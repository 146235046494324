class _Config {
  constructor() {
    this._config = window.appState.config || {};
  }

  get(name) {
    return this._config[name];
  }

  init(config) {
    // This overwrites the config from window.appState.config; perhaps we should force a init from all users?
    if (config) {
      this._config = config;
    }
  }
}

export const config = new _Config();
