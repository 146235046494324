import React, {createContext, useEffect, useReducer} from 'react';
import {config} from '../config/config';
import {IntlProvider} from 'react-intl';

export const LangContext = createContext();

// Used to calculate the initial state. Uses the window.translations array to populate values
const createInitialState = () => {
  let obj = {
    locale: config.get('locale'),
    translations: {},
  };

  obj.translations[config.get('locale')] = window.translations.keys;
  obj.translations[window.translations.locale] = window.translations.keys;

  return obj;
};

const initialState = createInitialState();

function reducer(state, action) {
  switch (action.type) {
    case 'changeLocale':
      return {...state, locale: action.locale};

    default:
      throw new Error('Unmapped action: ' + action);
  }
}

let lastArr;

export const LangContextWrapper = ({children}) => {
  const reducerArray = useReducer(reducer, initialState);

  useEffect(() => {
    console.log('LC', lastArr, reducerArray, lastArr === reducerArray);
    lastArr = reducerArray;
  });

  const locale = reducerArray[0].locale;
  const messages = reducerArray[0].translations[locale] || [];

  return (
    <LangContext.Provider value={reducerArray}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
};
