import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import useFormattedMessage from './useFormattedMessage';
import {toast} from 'react-toastify';

function InnerDisplay({message, titleKey, messageKey}) {
  const titleElement = titleKey ? <h3>{titleKey}</h3> : <h3>Error</h3>;
  const messageElement = messageKey ? <div>{messageKey}</div> : <div>{message}</div>;

  return (
    <div className="font-weight-bold small mb-0">
      <p className="mb-2">
        {titleElement}
        {messageElement}
      </p>
    </div>
  );
}

InnerDisplay.propTypes = {
  message: PropTypes.string,
  titleKey: PropTypes.string,
  messageKey: PropTypes.string,
};

export function useErrorDisplay(exception) {
  const {formatMessage} = useFormattedMessage();

  useEffect(() => {
    if (!exception) return;

    if (exception.response) {
      // Request made and server responded
      console.log('Server error: data', exception.response.data);
      console.log('Server error: status', exception.response.status);
      console.log('Server error: headers', exception.response.headers);
      toast.error(
        <InnerDisplay
          titleKey={formatMessage('error.server_error.title')}
          messageKey={formatMessage('error.server_error')}
        />,
      );
    } else if (exception.request) {
      // The request was made but no response was received. Server or network may be down.
      console.log('No response exception, network connectivity problems, or server down', exception.request);
      toast.error(
        <InnerDisplay titleKey={formatMessage('error.network.title')} messageKey={formatMessage('error.network')} />,
      );
    } else {
      // Something happened in setting up the request that triggered an Error. This should not happen, so output a crude error message.
      console.log('Error when setting up request', exception.message);
      toast.error(<InnerDisplay message={`Internal error: ${exception.message}`} />);
    }
  }, [exception]);
}

useErrorDisplay.propTypes = {
  exception: PropTypes.object.isRequired,
};
