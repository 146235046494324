/**
 * Setup of options for axios fetch.
 *
 * Warning: If we go back to fetch, make sure to change:
 *
 *   1) headers (not a plain object in fetch) and
 *   2) credentials are "withCredentials: true" in fetch, "credentials: 'include'" in axios.
 */

export const get = config => {
  return {
    method: 'GET',
    mode: 'cors',
    headers: {'Content-Type': 'application/json; charset=UTF-8'},
    withCredentials: true,
    ...config,
  };
};

export const post = config => {
  return {
    method: 'POST',
    mode: 'cors',
    headers: {'Content-Type': 'application/json; charset=UTF-8'},
    withCredentials: true,
    data: {},
    ...config,
  };
};

export const imagePost = config => {
  return {
    method: 'POST',
    mode: 'cors',
    withCredentials: true,
    ...config,
  };
};

export const del = config => {
  return {
    method: 'DELETE',
    mode: 'cors',
    headers: {'Content-Type': 'application/json; charset=UTF-8'},
    withCredentials: true,
    ...config,
  };
};

export const patch = config => {
  return {
    method: 'PATCH',
    mode: 'cors',
    headers: {'Content-Type': 'application/json; charset=UTF-8'},
    withCredentials: true,
    body: JSON.stringify({}),
    ...config,
  };
};
