import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {post} from '../../fetch/fetchOptions';
import axios from 'axios';
import {useErrorDisplay} from '../../common/useErrorDisplay';
import {HybridPage} from './HybridPage';
import {emailLoginUrl, hybridEmailCheckerUrl} from '../../fetch/urls';

export function Hybrid({details, updateDispatcher}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordEnabled, setPasswordEnabled] = useState(false);

  useErrorDisplay(error);

  const submit = async ({email, password, rememberMe}) => {
    setLoading(true);
    setError(null);

    async function doPostEmail() {
      try {
        const res = await axios(
          post({
            url: hybridEmailCheckerUrl(),
            data: {
              email,
              ...(details && details.queryString && {queryString: details.queryString}),
            },
          }),
        );
        setLoading(false);
        const action = res.data.action;
        switch (action) {
          case 'password':
            setPasswordEnabled(true);
            break;
          case 'redirect':
            updateDispatcher(res.data);
            break;
          default:
            console.log(`Error: Unknown action in result from ${hybridEmailCheckerUrl()}: ${action})`);
        }
      } catch (exception) {
        console.log('Exception when submitting data: ', exception);
        setLoading(false);
        setError(exception);
      }
    }

    async function doPostPassword() {
      try {
        const res = await axios(
          post({
            url: emailLoginUrl(),
            data: {
              email,
              password,
              rememberMe,
              ...(details && details.queryString && {queryString: details.queryString}),
            },
          }),
        );
        setLoading(false);
        updateDispatcher(res.data);
      } catch (exception) {
        console.log('Exception when submitting data: ', exception);
        setLoading(false);
        setError(exception);
      }
    }

    if (passwordEnabled) {
      await doPostPassword();
    } else {
      await doPostEmail();
    }
  };

  return (
    <HybridPage
      loading={loading}
      passwordEnabled={passwordEnabled}
      submit={submit}
      setPasswordDisabled={() => setPasswordEnabled(false)}
      details={details}
    />
  );
}

Hybrid.propTypes = {
  updateDispatcher: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
};
