import {config} from '../config/config';

const loginApi = config.get('apiUrls').login;
const druwaLoginApi = config.get('baseUrls').login;

const apiweb = config.get('apiUrls').apiweb;

export const emailLoginUrl = () => `${loginApi}/login/emaillogin`;
export const hybridEmailCheckerUrl = () => `${loginApi}/login/hybridemailchecker`;

export const confirmUserDetailsUrl = () => `${loginApi}/login/confirmdetails`;
export const confirmTOSUrl = () => `${loginApi}/login/confirmtos`;

export const setPasswordUrl = () => `${loginApi}/login/setpassword`;
export const resetPasswordUrl = () => `${loginApi}/login/resetpassword`;

export const requestResetPasswordUrl = () => `${apiweb}reset/email`;

export const twofactorauthenticateUrl = () => `${loginApi}/login/twofactorauthenticate`;

export const customFieldChoicesUrl = (lsid, fieldId, locale) =>
  `${druwaLoginApi}customfield/choices/${lsid}?lang=${locale}&fieldId=${fieldId}`;
