import React from 'react';

export const FormLabel = ({name, label, regObj, required, className}) => {
  if (!label) {
    return null;
  }

  return (
    <label htmlFor={name} className={className}>
      {label}
      {required ? <span aria-hidden={'true'}>{'\u002A'}</span> : null}
    </label>
  );
};
