import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useErrorDisplay} from '../../common/useErrorDisplay';
import {SingleHybridPage} from './SingleHybridPage';

export function SingleHybrid({details, updateDispatcher, ssoState, passwordState}) {
  const [error, setError] = useState(null);

  useErrorDisplay(error);

  const submit = ({method}) => {
    switch (method) {
      case 'sso':
        updateDispatcher(ssoState());
        break;
      case 'password':
        updateDispatcher(passwordState());
        break;
      default:
        console.log('Unknown method', method);
        setError({something: method});
    }
  };

  return <SingleHybridPage submit={submit} />;
}

SingleHybrid.propTypes = {
  details: PropTypes.object.isRequired,
  updateDispatcher: PropTypes.func.isRequired,
  ssoState: PropTypes.object.isRequired,
  passwordState: PropTypes.object.isRequired,
};
