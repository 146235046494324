import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {webFeature} from '../../config/webFeature';
import {Col, Form, Row, Button} from 'reactstrap';
import {useForm, FormContext} from 'react-hook-form';
import {LabeledInput} from '../../common/labeledinput/LabeledInput';
import {EMAIL_PATTERN, USERNAME_PATTERN} from '../../constants/regexPatterns';
import {SocialLogin} from '../../common/social/SocialLogin';
import {SocialLoginDivider} from '../../common/social/SocialLoginDivider';
import {SupportLinks} from '../../common/SupportLinks';
import {FormattedMessage} from 'react-intl';
import useFormattedMessage from '../../common/useFormattedMessage';
import {usernameEnabled} from '../../config/username';

// Note: This is from a config value
const rememberMeEnabled = webFeature.enabled('rememberlogin');

export function PasswordPage({submit, details}) {
  const {email} = details;
  const {facebook, linkedin, google} = details.socialLoginLinks;
  const methods = useForm();

  const {formatMessage} = useFormattedMessage();

  const hideLabel = window.appState.config.aleTheme === 'bx2023';

  useEffect(() => {
    if (email) {
      methods.setValue('email', email);
    }
  }, [email]);

  const onSubmit = submitObj =>
    submit({
      email: submitObj.email,
      password: submitObj.password,
      ...(rememberMeEnabled && {rememberMe: submitObj.rememberMe}),
    });

  let inputType, inputPattern;
  if (usernameEnabled()) {
    inputType = 'text';
    inputPattern = USERNAME_PATTERN;
  } else {
    inputType = 'email';
    inputPattern = EMAIL_PATTERN;
  }

  return (
    <>
      <FormContext {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col className="d-flex justify-content-center">
              <FormattedMessage id="loginsite.password.login" tagName="h2" />
            </Col>
          </Row>

          {(facebook || linkedin || google) && (
            <>
              <SocialLogin socialLoginLinks={details.socialLoginLinks} />
              <SocialLoginDivider />
            </>
          )}
          <Row>
            <Col xs={12}>
              <LabeledInput
                type={inputType}
                hideLabel={hideLabel}
                name="email"
                label={formatMessage(usernameEnabled() ? 'loginsite.username.label' : 'loginsite.email.label')}
                placeholder={formatMessage(
                  usernameEnabled() ? 'loginsite.username.placeholder' : 'loginsite.email.placeholder',
                )}
                regObj={inputPattern}
                hasAutoFocus
                autoComplete="username"
                required
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <LabeledInput
                type="password"
                name="password"
                hideLabel={hideLabel}
                label={formatMessage('loginsite.password.label')}
                placeholder={formatMessage('loginsite.password.placeholder')}
                regObj={{required: true}}
                autoComplete="current-password"
              />
            </Col>
          </Row>

          {rememberMeEnabled && (
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-center mt-1">
                  <LabeledInput
                    className="form-control"
                    type="checkbox"
                    hideLabel={hideLabel}
                    name="rememberMe"
                    label={formatMessage('loginsite.remember.label')}
                    defaultValue={false}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12}>
              <Button className="btn btn-block" type="submit" color="primary" disabled={methods.formState.isSubmitting}>
                <FormattedMessage id="loginsite.action.login" />
              </Button>
            </Col>
          </Row>

          <SupportLinks hasForgotPasswordLink={true} hasBackToStartLink={false} />
        </Form>
      </FormContext>
    </>
  );
}

PasswordPage.propTypes = {
  details: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
};
