import React from 'react';
import {Container, Col, Row} from 'reactstrap';
import {FormattedMessage} from 'react-intl';
import {realmConfig} from '../config/realmConfig';
import {config} from '../config/config';
import PoweredBy from '../common/PoweredBy';
const privacyPolicyUrl = realmConfig.get('cocosite.privacypolicy.url');
const tosUrl = realmConfig.get('cocosite.tos.url');

export default function Footer() {
  const copyright = config.get('copyright');
  return (
    <footer>
      <div className="hidden-xs">
        <Container lang="sv">
          <Row>
            <Col id="terms" md={3} sm={6} className="d-flex align-items-center login-footer-col">
              <div>
                <p className="mb-0">
                  {/* Safe link to our own website */
                  /* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a className="mr-1" href={privacyPolicyUrl} target="_blank" rel="noopener">
                    <FormattedMessage id="loginsite.footer.privacy" />
                  </a>
                  |
                  {/* Safe link to our own website */
                  /* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a className="ml-1" href={tosUrl} target="_blank" rel="noopener">
                    <FormattedMessage id="loginsite.footer.terms" />
                  </a>
                </p>
              </div>
            </Col>
            <Col
              id="copyright"
              md={6}
              className="hidden-sm login-footer-col d-flex align-items-center justify-content-center"
            >
              <span>
                <p className="mb-0" dangerouslySetInnerHTML={{__html: copyright}}></p>
              </span>
            </Col>
            <Col
              id="powered"
              md={3}
              sm={6}
              className="pull-right login-footer-col d-flex align-items-center justify-content-end"
            >
              <PoweredBy />
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
