import React from 'react';
import {FormattedMessage} from 'react-intl';
import {config} from '../../config/config';

export function Message() {
  const titleKey = config.get('titleKey');
  const messageKey = config.get('messageKey');
  return (
    <div className="text-center mt-2">
      {titleKey && <FormattedMessage id={titleKey} tagName="h1" />}
      {messageKey && <FormattedMessage id={messageKey} />}
    </div>
  );
}
