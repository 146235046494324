import {library} from '@fortawesome/fontawesome-svg-core';

/*import {

} from '@fortawesome/pro-light-svg-icons';*/

import {faUndo, faRedo, faArrowsAltH, faArrowsAltV} from '@fortawesome/pro-regular-svg-icons';

import {faCircle as faCircleSolid} from '@fortawesome/pro-solid-svg-icons';

/*import {} from '@fortawesome/free-solid-svg-icons';*/
import {faGoogle, faFacebookF, faLinkedinIn, faLinkedin} from '@fortawesome/free-brands-svg-icons';

library.add(faCircleSolid);
library.add(faGoogle);
library.add(faFacebookF);
library.add(faLinkedinIn);
library.add(faLinkedin);
library.add(faUndo);
library.add(faRedo);
library.add(faArrowsAltH);
library.add(faArrowsAltV);

export const iconLibrary = null; // This was originally a module without exports, but can not figure out how to include this in jslib-utils.
