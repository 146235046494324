import React, {useState} from 'react';
import axios from 'axios';
import {useErrorDisplay} from '../../common/useErrorDisplay';
import {LoadingSpinner} from '../../common/LoadingSpinner';
import {requestResetPasswordUrl} from '../../fetch/urls';
import {post} from '../../fetch/fetchOptions';
import {ForgotPasswordPage} from './ForgotPasswordPage';
import {SentPage} from './SentPage';

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);

  useErrorDisplay(error);

  const submit = async ({email}) => {
    setLoading(true);
    setError(null);

    async function doPost() {
      try {
        await axios(
          post({
            url: requestResetPasswordUrl(),
            data: {email},
          }),
        );
        setLoading(false);
        setDone(true);
      } catch (exception) {
        console.log('Exception when submitting data: ', exception);
        setLoading(false);
        setError(exception);
      }
    }

    await doPost();
  };

  if (loading) {
    return <LoadingSpinner />;
  } else if (done) {
    return <SentPage />;
  } else {
    return <ForgotPasswordPage submit={submit} />;
  }
}
