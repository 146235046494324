import React, {Suspense} from 'react';
import {LangContextWrapper} from './context/LangContext';
import {MainRouter} from './MainRouter';

import {iconLibrary} from './common/iconLibrary';
import {BackgroundContainer} from './layouts/BackgroundContainer';
import {ErrorBoundary} from './error/ErrorBoundary';
import {toast} from 'react-toastify';
import {DEFAULT_CONFIG} from './config/toastConfig';
import 'react-toastify/dist/ReactToastify.css';
import 'focus-visible';

const library = iconLibrary; // eslint-disable-line
toast.configure(DEFAULT_CONFIG);

export function App() {
  return (
    <ErrorBoundary>
      <LangContextWrapper>
        <BackgroundContainer />
        <Suspense fallback={<div>{/* Loading */}</div>}>
          <MainRouter />
        </Suspense>
      </LangContextWrapper>
    </ErrorBoundary>
  );
}
