import React from 'react';

export const FormInputCheckbox = ({register, name, type, regObj, disabled}) => (
  <input
    id={name}
    type={type}
    className="form-check-input"
    defaultChecked={false}
    name={name}
    ref={register(regObj)}
    disabled={disabled}
  />
);
