import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {MainLayout} from './layouts/MainLayout';
import {ForgotPassword} from './pages/forgotPassword/ForgotPassword';
import {Dispatcher} from './Dispatcher';
import {Switch} from 'react-router';
import {FORGOT_PASSWORD_ROUTE, MESSAGE_PAGE_ACCOUNTLOCKED_ROUTE, MESSAGE_PAGE_ROUTE} from './constants/routes';
import {Message} from './pages/message/Message';

export function MainRouter() {
  function LayoutRoute({Layout, render, ...rest}) {
    return <Route {...rest} render={matchProps => <Layout status="ok">{render({...matchProps})}</Layout>} />;
  }
  return (
    <BrowserRouter basename={`${window.contextPath}`}>
      <Switch>
        <LayoutRoute Layout={MainLayout} path={`${FORGOT_PASSWORD_ROUTE}`} render={() => <ForgotPassword />} />
        <LayoutRoute Layout={MainLayout} path={`${MESSAGE_PAGE_ACCOUNTLOCKED_ROUTE}`} render={() => <Message />} />
        <LayoutRoute Layout={MainLayout} path={`${MESSAGE_PAGE_ROUTE}`} render={() => <Message />} />
        <Route component={Dispatcher} />
      </Switch>
    </BrowserRouter>
  );
}
