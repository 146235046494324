import React from 'react';
import PropTypes from 'prop-types';
import {Row, Button, Col, Form} from 'reactstrap';
import {LabeledInput} from '../../common/labeledinput/LabeledInput';
import {EMAIL_PATTERN} from '../../constants/regexPatterns';
import {useForm, FormContext} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {SupportLinks} from '../../common/SupportLinks';
import useFormattedMessage from '../../common/useFormattedMessage';

export function ForgotPasswordPage({submit}) {
  const methods = useForm();
  const {formatMessage} = useFormattedMessage();

  const hideLabel = window.appState.config.aleTheme === 'bx2023';

  const onSubmit = submitObj =>
    submit({
      email: submitObj.email,
    });

  return (
    <FormContext {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} className="mb-2">
            <div className="text-center">
              <FormattedMessage id="loginsite.action.getpw" tagName="h2" />
              <FormattedMessage id="loginsite.forgotpw.intro.text" tagName="p" />
            </div>

            <LabeledInput
              type="text"
              hideLabel={hideLabel}
              name="email"
              label={formatMessage('loginsite.email.label')}
              placeholder={formatMessage('loginsite.email.placeholder')}
              className="form-control"
              regObj={EMAIL_PATTERN}
              hasAutoFocus
              required
            />
          </Col>
          <Col xs={12}>
            <Button className="btn btn-block" color="primary" type="submit" disabled={methods.formState.isSubmitting}>
              <FormattedMessage id="loginsite.forgotpw.requestnewpass" />
            </Button>
          </Col>
        </Row>
        <SupportLinks hasForgotPasswordLink={false} hasBackToStartLink={true} />
      </Form>
    </FormContext>
  );
}

ForgotPasswordPage.propTypes = {
  email: PropTypes.string,
  submit: PropTypes.func.isRequired,
};
