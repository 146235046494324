import React from 'react';
import PropTypes from 'prop-types';
//import {webFeature} from '../../config/webFeature';
import {Button, Col, Form, Row} from 'reactstrap';
import {useForm, FormContext} from 'react-hook-form';
import {LabeledInput} from '../../common/labeledinput/LabeledInput';
import {PASSWORD_PATTERN} from '../../constants/regexPatterns';
import {SupportLinks} from '../../common/SupportLinks';
import {FormattedMessage} from 'react-intl';
import useFormattedMessage from '../../common/useFormattedMessage';

// Note: This is from a config value
//const rememberMeEnabled = webFeature.enabled('rememberlogin');

export function ResetPasswordPage({submit, rememberMe}) {
  const methods = useForm();
  const {formatMessage} = useFormattedMessage();
  const onSubmit = submitObj =>
    submit({
      password: submitObj.passwordVal,
    });

  const hideLabel = window.appState.config.aleTheme === 'bx2023';

  return (
    <div className="xs-input">
      <FormContext {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <div className="text-center">
                <FormattedMessage id="loginsite.setpw.intro.title" tagName="h2" />
                <FormattedMessage id="loginsite.setpw.intro.text" tagName="p" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={'mb-3'}>
              <LabeledInput
                className="form-control"
                hideLabel={hideLabel}
                type="password"
                name="passwordVal"
                label={formatMessage('loginsite.setpw.label')}
                placeholder={formatMessage('loginsite.password.placeholder')}
                regObj={PASSWORD_PATTERN}
                hasAutoFocus
                required
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Button
                className="btn btn-block"
                color="primary"
                type="submit"
                value="Set password"
                disabled={methods.formState.isSubmitting}
              >
                <FormattedMessage id="loginsite.action.setpw" />
              </Button>
            </Col>
          </Row>

          <SupportLinks hasForgotPasswordLink={false} hasBackToStartLink={false} />
        </Form>
      </FormContext>
    </div>
  );
}

ResetPasswordPage.propTypes = {
  email: PropTypes.string,
  submit: PropTypes.func.isRequired,
};
