import {config} from './config';

class _WebFeature {
  constructor() {
    this._features = config.get('webfeatures') || {};
  }

  enabled(name) {
    const f = this._features[name];
    return f;
  }
}

export const webFeature = new _WebFeature();
