import React from 'react';
import {PropagateLoader} from 'react-spinners';
import PropTypes from 'prop-types';

export const LoadingSpinner = ({center}) => (
  <div className={'d-flex justify-content-center'} style={center ? {marginTop: -15} : undefined}>
    <PropagateLoader className="" sizeUnit={'px'} size={15} color={'#ced4da'} loading={true} />
  </div>
);

LoadingSpinner.displayName = 'LoadingSpinner';

LoadingSpinner.propTypes = {
  center: PropTypes.bool,
};
